@use '../../../../styles/variables';

.shareLive {
  align-items: center;
  background-color: var(--share-live-background);
  border-radius: var(--button-border-radius);
  border-radius: 3px;
  color: var(--carousel-tabs-icon-color-active);
  display: flex;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  justify-content: center;
  letter-spacing: 1px;
  opacity: 0.8;
  padding: 2px 10px;
  position: absolute;
  right: 40px;
  text-decoration: none;
  top: 25px;
  transform: translate(-50%, 0);
  transition: 0.2s background-color;
  user-select: none;
  z-index: 1;

  &:hover {
    background-color: var(--share-live-background-hover);
  }
}

.shareLiveIcon {
  margin-bottom: -2px;
  margin-right: 5px;
  width: 25px;

  path {
    fill: var(--carousel-tabs-icon-color-active);
  }
}
