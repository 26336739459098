@use '../../../../styles/breakpoints';
@use '../../../../styles/variables';

$sidebar-width: 55px;
$sidebar-width-mobile: 50px;

.container {
  align-items: center;
  background: none;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  position: fixed;
  transition: right 0.2s;
}

.templateDrawer {
  height: 100vh;
  overflow: hidden;
  width: 300px;
}

.sidebar {
  align-items: center;
  box-shadow: -1px 0 5px 0 rgba(50, 50, 50, 75%);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  @media (max-width: variables.$breakpoint-sm) {
    box-shadow: none;
  }

  @media (max-width: variables.$breakpoint-sm) and (orientation: landscape) {
    justify-content: flex-start;
  }
}
