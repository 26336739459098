@use '../../../../../../styles/variables';
@use '../../../../../../styles/breakpoints';

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.icons {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  grid-gap: 30px;
  margin: 25px 0 70px;
}

.socialMediaButton {
  align-items: center;
  background-color: var(--modal-icon-color);
  border: 1px solid var(--modal-icon-color);
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  height: 85px;
  justify-content: center;
  margin-bottom: 10px;
  opacity: 1;
  padding: 0 20px;
  text-decoration: none;
  transition: 0.2s filter;
  width: 85px;

  &:hover {
    filter: brightness(0.75);
    transition: 0.2s filter;
  }

  path {
    fill: var(--modal-background);
  }

  @media (max-width: breakpoints.$breakpoint-sm) {
    height: 60px;
    width: 60px;
  }
}

.linkContainer {
  text-align: center;
}

.linkLabel {
  color: var(--modal-color);
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
}

.shareLinkContainer {
  display: flex;
  gap: 1rem;

  @media (max-width: breakpoints.$breakpoint-sm) {
    align-items: center;
    flex-direction: column;
  }

  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: landscape) {
    flex-direction: row;
  }
}

.shareLink {
  background-color: white;
  color: variables.$grey;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  line-height: 22px;
  overflow: hidden;
  padding: 15px 20px;
  text-overflow: ellipsis;
  user-select: text;
  white-space: nowrap;
  word-break: break-all;

  @media (max-width: breakpoints.$breakpoint-sm) {
    background-color: #f4f4f4;
    line-height: 20px;
    max-width: 333px;
  }
}

.button {
  background-color: var(--modal-icon-color);
  border: none;
  border-radius: 5px;
  color: var(--modal-background);
  cursor: pointer;
  height: 50px;
  width: 109px;
}
