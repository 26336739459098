@use '../../../../styles/breakpoints';
@use '../../../../styles/variables';

.container {
  align-items: center;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 1.5rem;
  width: 100%;
}

.infoPod {
  align-items: center;
  background-color: var(--modal-background);
  border-radius: 8px;
  color: var(--modal-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 35%;
  padding: 1rem 1.5rem;
}

.topRow,
.bottomRow {
  align-items: center;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  justify-content: flex-start;
  line-height: 19px;
  margin: 5px 0;
  padding: 0;
  width: 100%;
}

.topRow div,
.bottomRow div {
  margin-right: 16px;
}
