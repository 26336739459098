.title {
  color: var(--modal-color);
  font-size: 20px;
  font-weight: 700;
}

.shareTitle {
  color: var(--modal-color);
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
}

.socialMediaButtons {
  display: flex;
  gap: 35px;
  justify-content: space-around;
  margin-bottom: 35px;
  margin-top: 25px;
}

.socialMediaButton {
  align-items: center;
  color: var(--modal-color);
  display: flex;
  flex-direction: column;
  font-weight: 600;
  text-decoration: none;
  transition: 0.2s filter;

  &:hover {
    filter: brightness(0.75);
    transition: 0.2s filter;
  }
}

.icon {
  align-items: center;
  background: var(--modal-icon-color);
  border: 1px solid var(--modal-icon-color);
  border-radius: 100%;
  color: var(--modal-icon-color);
  cursor: pointer;
  display: flex;
  font-size: 14px;
  height: 50px;
  justify-content: center;
  margin-bottom: 10px;
  text-decoration: none;
  width: 50px;

  svg {
    height: 18px;
    width: 30px;

    path {
      fill: var(--modal-background);
    }
  }
}

.button {
  background-color: var(--modal-icon-color);
  border: none;
  border-radius: 5px;
  color: var(--modal-background);
  cursor: pointer;
  height: 30px;
  margin-right: 2px;
  text-align: center;
  width: 75px;
}

.copyLink {
  align-items: center;
  border: 1px solid var(--modal-icon-color);
  border-radius: 5px;
  color: var(--modal-icon-color);
  display: flex;
  font-size: 14px;
  height: 35px;
  justify-content: space-between;
  margin-bottom: 20px;
  text-decoration: none;
  transition: 0.2s filter;
  width: 100%;
}

.shareLink {
  flex-grow: 1;
  overflow: hidden;
  padding: 15px;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  width: 0;
}

.shareIcon {
  align-items: center;
  border-right: 1px solid var(--modal-icon-color);
  display: flex;
  height: 100%;
  padding: 0 15px;
}
