@use '../../../../../../styles/variables';
@use '../../../../../../styles/breakpoints';

.container {
  align-items: center;
  display: flex;
  flex-direction: column;

  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: landscape) {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 3fr;
    max-width: 100%;
  }
}

.titleBar {
  color: var(--modal-color);

  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: landscape) {
    grid-column: 1;
    grid-row: 1;
  }
}

.title {
  font-size: 20px;
  font-weight: 700;
}

.address {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}

.actions {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 20px;

  svg {
    margin-right: 5px;
  }

  path {
    fill: var(--modal-background);
  }
}

.action {
  align-items: center;
  background-color: var(--modal-icon-color);
  border: none;
  border-radius: 5px;
  color: var(--modal-background);
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  margin: 0 1rem;
  padding: 0.5rem;
  text-decoration: none;
  width: 10rem;

  @media (max-width: breakpoints.$breakpoint-sm) {
    font-size: 14px;
    padding: 10px 20px;
    width: auto;
  }

  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: landscape) {
    margin: 10px auto;
  }

  &:hover {
    background-color: var(--modal-icon-color-hover);
  }
}

.map {
  height: 160px;
  margin-bottom: 10px;
  position: relative;
  width: 250px;

  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: portrait) {
    height: calc(100vw - 170px);
    width: calc(100vw - 70px);
  }

  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: landscape) {
    width: 100%;
  }
}

.mapBar {
  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: landscape) {
    grid-column: 1;
    grid-row: 2;
  }
}

.nearby {
  color: var(--modal-color);
  margin-top: 25px;
  width: 100%;

  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: landscape) {
    grid-column: 2;
    grid-row: 1 / span 2;
    height: 100%;
  }
}

.nearbyTitle {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.nearbySectionTitle {
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 10px;

  svg {
    height: 16px;
    margin-right: 5px;
    width: 16px;
  }
}

.nearbyPlace {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.nearbyPlaceName {
  font-size: 14px;
  line-height: 24px;
  max-width: 175px;
}

.nearbyPlaceDistance {
  font-size: 14px;
  line-height: 24px;
}
