@use '../../../../../../styles/variables';
@use '../../../../../../styles/breakpoints';

.container {
  align-items: center;
  background-color: variables.$black;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;

  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: portrait) {
    height: 95%;
  }
}
