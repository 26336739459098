@use 'styles/variables';

@import-normalize;

:root {
  --app-height: 100%;
}

// TODO load font-face only for theme that needs it
@font-face {
  font-family: icomoon;
  src: url('./assets/fonts/icomoon/icomoon.eot');
  src: url('./assets/fonts/icomoon/icomoon.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/icomoon/icomoon.woff') format('woff'),
    url('./assets/fonts/icomoon/icomoon.ttf') format('truetype'),
    url('./assets/fonts/icomoon/icomoon.svg#icomoon') format('svg');
}

// TODO load font-face only for theme that needs it
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  src: url('https://prodtiles.panoskincdn.com/SVE/STATIC/fonts/noto-sans-v11-latin/noto-sans-v11-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans'), local('NotoSans'),
    url('https://prodtiles.panoskincdn.com/SVE/STATIC/fonts/noto-sans-v11-latin/noto-sans-v11-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('https://prodtiles.panoskincdn.com/SVE/STATIC/fonts/noto-sans-v11-latin/noto-sans-v11-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('https://prodtiles.panoskincdn.com/SVE/STATIC/fonts/noto-sans-v11-latin/noto-sans-v11-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */
      url('https://prodtiles.panoskincdn.com/SVE/STATIC/fonts/noto-sans-v11-latin/fonts/noto-sans-v11-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('https://prodtiles.panoskincdn.com/SVE/STATIC/fonts/noto-sans-v11-latin/fonts/noto-sans-v11-latin-regular.svg#NotoSans')
      format('svg'); /* Legacy iOS */
}

html {
  color: variables.$font-color;
  font-size: variables.$font-size;
  line-height: variables.$line-height;
}

body {
  margin: 0;
  user-select: none;
}

html,
body {
  height: calc(
    100vh - env(safe-area-inset-bottom, 0) - env(safe-area-inset-top, 0)
  );
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

.fullsize {
  height: 100%;
  position: absolute;
  top: 0;
  width: 100vw;

  &:empty {
    display: none;
  }
}

#image {
  background-color: variables.$grey;
}

@supports (-webkit-touch-callout: none) {
  body,
  html {
    /* for ios safari 15, safe-area-inset-bottom is 0, so a special fix apply here */
    // stylelint-disable-next-line value-no-vendor-prefix
    height: var(--app-height);
  }
}

#root {
  background-color: transparent;
  pointer-events: none;
  position: absolute;
  top: 0;
}
