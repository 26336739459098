@use '../../../../../../styles/breakpoints';
@use '../../../../../../styles/variables';

.container {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;
}

.menuDialog {
  border-radius: 4px;
  bottom: 90px;
  min-width: 300px;
  padding: 20px 30px;
  position: fixed;
  z-index: 10;

  @media (max-width: breakpoints.$breakpoint-sm) {
    border-radius: 0;
    max-height: 100%;
    max-width: 100%;
    padding: 40px;
    width: 640px;
  }
}

.menuDialogUnits {
  border-radius: 8px;
  box-shadow: variables.$box-shadow;
  color: var(--modal-color);
  max-height: calc(var(--app-height) - 40px);
  max-width: 800px;
  overflow: auto;
  padding: 60px 80px;
  position: relative;
  width: 100%;

  @media (max-width: breakpoints.$breakpoint-sm) {
    border-radius: 0;
    max-height: 100%;
    max-width: 100%;
    padding: 40px;
    width: 640px;
  }
}

.menuDialogMobile {
  bottom: 0;
  height: 100%;
  left: 0;
  padding-top: 60px;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 10;
}

.close {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 18px;

  path {
    fill: #afafaf;
  }

  &:hover {
    filter: brightness(0.8);
    transition: 0.2s filter;
  }

  @media (max-width: breakpoints.$breakpoint-sm) {
    right: 15px;
    top: 15px;
  }
}
