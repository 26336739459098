@use '../../styles/breakpoints';
@use '../../styles/variables';

.map {
  height: 100vh;
  pointer-events: all;
  position: absolute;
  width: 100vw;
  z-index: 0;
}

.directions.directions {
  align-items: center;
  background-color: var(--menu-background);
  border-radius: var(--button-border-radius);
  color: var(--menu-icon-color-default);
  display: flex;
  font-size: 1rem;
  gap: 0.5rem;
  justify-content: center;
  margin: 1rem 0;
  padding: 0.5rem;
  text-decoration: none;

  svg {
    fill: var(--menu-icon-color-default);
    margin-bottom: 2px;
  }

  &:hover {
    filter: brightness(0.9);
    transition: 0.2s filter;
  }

  @media (max-width: breakpoints.$breakpoint-sm) {
    font-size: 14px;
    padding: 10px 20px;
  }
}

.close {
  position: absolute;
  right: 120px;
  top: 25px;
  z-index: 400;

  path {
    fill: var(--menu-background);
  }

  &:hover {
    filter: brightness(0.8);
    transition: 0.2s filter;
  }

  @media (max-width: breakpoints.$breakpoint-sm) {
    right: 90px;
  }

  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: landscape) {
    right: 150px;
  }
}
