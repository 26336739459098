@use '../../../../../../styles/variables';
@use '../../../../../../styles/breakpoints';

.container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;

  @media (max-width: breakpoints.$breakpoint-sm) {
    height: 95%;
  }
}

.image {
  height: 100%;
  object-fit: contain;
  position: relative;
  width: 100%;
  z-index: 1;
}

.loading {
  align-items: center;
  color: variables.$white;
  display: flex;
  font-size: 1.6rem;
  justify-content: center;
  opacity: 0.75;
  position: fixed;
  z-index: 0;
}

@keyframes bounce-pulse {
  0% {
    transform: translateY(0.1875em);
  }

  30% {
    transform: translateY(-0.25em);
  }

  50% {
    transform: translateY(0.1875em);
  }
}

.bounce {
  display: inline-block;
  line-height: 0;

  & > div {
    animation-duration: inherit;
    animation-iteration-count: infinite;
    animation-name: bounce-pulse;
    animation-timing-function: ease-in-out;
    background-color: variables.$white;
    border-radius: 100%;
    display: inline-block;
    height: 0.5em;
    margin-right: 0.1875em;
    transform: translateY(0.1875em);
    width: 0.5em;

    &:last-child {
      margin-right: 0;
    }
  }
}
