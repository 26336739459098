$button-size: 40px;

.wrapper {
  align-items: center;
  color: var(--carousel-controls-color);
  display: inline-flex;
  height: $button-size;
  justify-content: center;
  opacity: 0.5;
  width: $button-size;
}
