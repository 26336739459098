@use '../../../../styles/variables';
@use '../../../../styles/breakpoints';

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;

  > div {
    margin-right: 1rem;
  }

  @media (max-width: breakpoints.$breakpoint-sm) {
    justify-content: space-between;
  }
}
