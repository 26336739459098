@use '../../../../styles/breakpoints';
@use '../../../../styles/variables';

.container {
  bottom: 10px;
  left: 12.5%;
  margin-bottom: 10px;
  padding-right: 35px;
  position: absolute;
  right: 12.5%;
  z-index: 2;

  @media (max-width: breakpoints.$breakpoint-sm) {
    bottom: 20px;
    left: 26px;
    padding-right: 0;
    right: 26px;
  }
}
