@use '../../styles/breakpoints';

.arrow-side {
  cursor: pointer;
  opacity: 0.5;
  position: absolute;
  top: 50%;
  transition: 0.2s opacity;
  width: 30px;
  z-index: 10;

  &:hover {
    opacity: 0.7;
    transition: 0.2s opacity;
  }
}

.arrowLeft-side {
  left: 25px;
  transform: translateY(-50%);
}

.arrowRight-side {
  right: 25px;
  transform: translateY(-50%) rotate(180deg);
}

.circle-bottom {
  align-items: center;
  background: rgba(255, 255, 255, 90%);
  border-radius: 25px;
  bottom: 20px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 15%);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  position: absolute;
  transform: translate(-50%, 0);
  width: 50px;
  z-index: 2;

  &:hover {
    background: rgba(228, 221, 221, 90%);
  }
  @media (max-width: breakpoints.$breakpoint-sm) {
    bottom: 90px;
  }
}

.circleRight-bottom {
  left: calc(50% + 30px);
}

.circleLeft-bottom {
  left: calc(50% - 30px);
}

.arrow-bottom {
  color: #000;
  width: 9px;
}

.arrowLeft-bottom {
  transform: rotate(180deg);
}

.arrow-side-mobile {
  cursor: pointer;
  opacity: 0.5;
  position: absolute;
  top: 50%;
  transition: 0.2s opacity;
  z-index: 10;

  &:hover {
    opacity: 0.7;
    transition: 0.2s opacity;
  }
}

.arrowLeft-side-mobile {
  left: 25px;
  transform: translateY(-50%);
  @media (orientation: landscape) {
    left: 100px;
  }
}

.arrowRight-side-mobile {
  right: 25px;
  transform: translateY(-50%) rotate(180deg);
  @media (orientation: landscape) {
    right: 100px;
  }
}

.arrow-tb-pro {
  background-color: var(--menu-button-background);
  border-radius: 100%;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 15%);
  color: var(--menu-button-color);
  cursor: pointer;
  height: 60px;
  opacity: 1;
  padding: 15px;
  position: absolute;
  top: 50%;
  transition: 0.2s filter;
  width: 60px;
  z-index: 10;

  &:hover {
    filter: brightness(1.25);
    transition: 0.2s filter;
  }
}

.arrowLeft-tb-pro {
  left: 12.5%;
  transform: translateY(-50%);

  @media (max-width: breakpoints.$breakpoint-sm) {
    height: 40px;
    left: 15px;
    width: 40px;
  }
}

.arrowRight-tb-pro {
  right: 12.5%;
  transform: translateY(-50%) rotate(180deg);

  @media (max-width: breakpoints.$breakpoint-sm) {
    height: 40px;
    right: 15px;
    width: 40px;
  }
}
