@use '../../../../../../styles/breakpoints';
@use '../../../../../../styles/variables';

.container {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;

  @media (max-width: breakpoints.$breakpoint-sm) {
    width: calc(100vw - 50px);
  }
}

.menuDialog {
  border-radius: 5px;
  box-shadow: 1px 1px 5px rbga(50, 50, 50, 0.65);
  color: var(--modal-color);
  max-height: calc(var(--app-height) - 40px);
  max-width: 800px;
  overflow: auto;
  padding: 40px 20px;
  position: relative;

  @media (max-width: breakpoints.$breakpoint-sm) {
    border-radius: 5px;
    max-height: 100%;
    max-width: 100%;
    padding: 40px;
    width: 640px;
  }
}

.menuDialogMobile {
  bottom: 0;
  height: 100%;
  left: 0;
  padding-top: 60px;
  right: 0;
  top: 0;
  width: 100%;
}

.close {
  cursor: pointer;
  font-size: 25px;
  height: 25px;
  position: absolute;
  right: 5px;
  top: 5px;
  width: 25px;

  path {
    fill: #afafaf;
  }

  &:hover {
    filter: brightness(0.8);
    transition: 0.2s filter;
  }

  @media (max-width: breakpoints.$breakpoint-sm) {
    left: 15px;
    top: 15px;
  }
}
