@use '../../../../../../styles/breakpoints';
@use '../../../../../../styles/variables';

.infoContainer,
.infoBackground {
  bottom: 0;
  display: flex;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
}

.infoBackground {
  background: #000 !important;
  opacity: 0.6;
}

.infoContent {
  background: transparent;
  box-shadow: variables.$box-shadow;
  color: #fff;
  display: flex;
  font-size: 15px;
  font-weight: bold;
  height: 100%;
  letter-spacing: 1px;
  line-height: 1.5;
  margin-top: 20px;
  opacity: 1;
  overflow: hidden;
  padding-left: 90px;
  padding-right: 50px;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 2;

  @media (max-width: breakpoints.$breakpoint-md) and (orientation: landscape) {
    justify-content: top;
  }

  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: portrait) {
    align-items: center;
    border-radius: 0;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    max-height: 100%;
    max-width: 100%;
    padding: 0;
  }
}

.leftContent {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  font-weight: bold;
  justify-content: center;
  justify-items: center;
  letter-spacing: 1px;
  line-height: 21px;
  margin-bottom: 50px;
  width: 30%;

  img {
    margin-bottom: 40px;
    width: 265px;
    @media (max-width: breakpoints.$breakpoint-sm) and (orientation: portrait) {
      margin-bottom: 20px;
    }
    @media (max-width: breakpoints.$breakpoint-md) and (orientation: landscape) {
      width: 200px;
    }
  }

  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: portrait) {
    margin: 0 0 40px;
    width: 100%;
  }
  @media (max-width: breakpoints.$breakpoint-md) and (orientation: landscape) {
    margin-right: 0;
  }
}

.rightContent {
  display: flex;
  flex-direction: column;
  font-size: 15px;
  height: 70%;
  justify-content: top;
  line-height: 21px;
  margin-left: 140px;
  margin-right: 70px;
  margin-top: 50px;
  overflow: auto;
  width: 70%;

  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: portrait) {
    margin: 0;
    overflow: auto;
  }
}

.close {
  color: var(--menu-icon-color-default);
  cursor: pointer;
  font-size: 25px;
  height: 25px;
  left: 25px;
  position: absolute;
  top: 14px;
  top: 11px;
  width: 18px;
  width: 25px;

  path {
    fill: #afafaf;
  }

  &:hover {
    filter: brightness(0.8);
    transition: 0.2s filter;
  }
}
