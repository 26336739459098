@use '../../../../styles/variables';
@use '../../../../styles/breakpoints';

.stats {
  --stat-size: 55px;

  display: flex;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  right: 20px;
  top: 20px;

  @media (max-width: breakpoints.$breakpoint-sm) {
    --stat-size: 50px;

    right: 10px;
  }
}

.stat {
  display: grid;
  gap: 4px 8px;
  grid-template-areas:
    '. details'
    'indicator details';
  grid-template-columns: 32px 1fr;
  justify-items: end;

  @media (max-width: breakpoints.$breakpoint-sm) {
    grid-template-columns: 24px 1fr;
  }
}

.statIcon {
  align-items: center;
  background-color: var(--stats-background);
  border-radius: 50px;
  color: var(--stats-icon-color);
  display: flex;
  justify-content: center;
  min-height: var(--stat-size);
  width: var(--stat-size);
}

.statLabel {
  background-color: var(--stats-background);
  border-radius: 4px;
  color: var(--stats-icon-color);
  font-size: 14px;
  padding: 3px;
  text-align: center;

  @media (max-width: breakpoints.$breakpoint-sm) {
    font-size: 12px;
  }
}

.statIndicator {
  align-items: center;
  display: flex;
  grid-area: indicator;

  img {
    width: 100%;
  }
}

.statDetails {
  align-items: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
  grid-area: details;
  justify-self: center;
}

.clickable:not(.disabled) {
  cursor: pointer;

  &:hover {
    filter: brightness(0.9);
    transition: 0.2s filter;
  }
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;

  svg {
    opacity: 0.5;
  }
}
