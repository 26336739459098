@use '../../../../../../styles/variables';
@use '../../../../../../styles/breakpoints';

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.icons {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  grid-gap: 30px;
  margin: 25px 0 8px;
  width: 100%;
}

.socialMediaButton {
  align-items: center;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  height: 21px;
  justify-content: center;
  margin: 8px 0;
  opacity: 1;
  padding: 0 20px;
  text-decoration: none;
  transition: 0.2s filter;
  width: 21px;

  &:hover {
    filter: brightness(0.75);
    transition: 0.2s filter;
  }

  path {
    fill: var(--modal-icon-color);
  }
}

.linkContainer {
  align-items: center;
  border: 1px solid #d7d7d7;
  border-radius: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  width: 100%;

  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: landscape) {
    width: 40%;
  }
}

.linkLabel {
  @if var(--modal-color) == var(--modal-background) {
    color: black;
  } @else {
    color: var(--modal-color);
  }

  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  margin-left: 20px;
}

.shareLinkContainer {
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  display: flex;
  gap: 1rem;

  @media (max-width: breakpoints.$breakpoint-sm) {
    align-items: center;
    flex-direction: column;
  }
}

.copyLink {
  align-items: center;
  border: 1px solid var(--modal-icon-color);
  border-radius: 5px;
  color: var(--modal-icon-color);
  display: flex;
  font-size: 14px;
  height: 35px;
  justify-content: space-between;
  margin-top: 10px;
  text-decoration: none;
  transition: 0.2s filter;
  width: 100%;

  @media (max-width: breakpoints.$breakpoint-sm) {
    margin-top: 20px;
  }
}

.shareIcon {
  align-items: center;
  border-right: 1px solid var(--modal-icon-color);
  display: flex;
  height: 100%;
  padding: 0 15px;
}

.shareLink {
  flex-grow: 1;
  overflow: hidden;
  padding: 15px;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  width: 0;
}

.button {
  background-color: var(--modal-icon-color);
  border: none;
  border-radius: 5px;
  color: var(--modal-background);
  cursor: pointer;
  height: 30px;
  margin-right: 2px;
  text-align: center;
  width: 75px;

  @media (max-width: breakpoints.$breakpoint-sm) {
    border-radius: 90px;
    width: 91px;
  }
}

.unitName {
  @if var(--modal-color) == var(--modal-background) {
    color: black;
  } @else {
    color: var(--modal-color);
  }

  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  margin-bottom: 10px;
  text-align: left;
  width: 100%;
}

.message {
  @if var(--modal-color) == var(--modal-background) {
    color: black;
  } @else {
    color: var(--modal-color);
  }

  font-size: 14px;
  font-weight: 600;
  line-height: 23px;
  text-align: left;
  width: 100%;
}

.mobileButton {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-top: 22px;
  width: 100%;
}
