@use '../../../../styles/variables';
@use '../../../../styles/breakpoints';

.container {
  align-items: center;
  background-color: var(--carousel-background);
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 0 15px;
  pointer-events: all;
  position: absolute;
  right: 0;
  z-index: 1;

  @media (max-width: breakpoints.$breakpoint-sm) {
    bottom: 0;
    right: 0;
    z-index: 3;
  }
}

.closeContainer {
  cursor: pointer;
  display: flex;
  height: 26px;
  justify-content: flex-end;
  margin-bottom: 20px;
  width: 100%;
}

.close {
  margin: 10px 10px 0 0;
  z-index: 2;
}

.imageContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;

  > img {
    height: 100%;
    width: 100%;
  }
}

.carousel.carousel {
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 0 0 30px;
  white-space: nowrap;
  width: 100%;

  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: portrait) {
    bottom: 0;
  }
}

.arrows {
  align-items: center;
  background-color: var(--carousel-background);
  cursor: 'pointer';
  display: flex;
  justify-content: space-between;
  left: '50%';
  opacity: 0.8;
  pointer-events: none;
  position: relative;
  transform: 'translateX(-50%)';
  z-index: 2;

  svg {
    cursor: pointer;
    pointer-events: all;
  }
  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: portrait) {
    background-color: transparent;
    z-index: 1;
  }
}

.controls {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 0;

  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: portrait) {
    margin-left: 10px;
  }
}

.pills {
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;
  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: portrait) {
    margin-bottom: 6px;
  }
}

.item {
  cursor: pointer;
  display: inline-block;
  height: 100px;
  margin: 0 3px;
  min-width: 200px;
  position: relative;

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

.active {
  border: 4px solid var(--carousel-title-color);
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3;
}

.closeArrow {
  rect {
    fill: var(--pill-background-active);
  }
}

.showArrow {
  transform: rotate(180deg);
}

.title {
  align-items: center;
  bottom: -24px;
  color: var(--menu-icon-color-default);
  display: flex;
  height: 24px;
  justify-content: space-around;
  position: absolute;
  width: 100%;

  > span {
    bottom: 0;
    color: var(--menu-icon-color-default);
    max-width: 180px;
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    top: 0;
    z-index: 3;
  }

  &::before {
    bottom: 0;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
}

.floorPlanIndicator {
  position: absolute;
  right: 4px;
  top: 4px;
}
